import SendGQL from '../SendGQL';

const FormList = `
  query FormList ($page: Int!, $perPage: Int!, $tag: String, $name: String, $status: String) {
    informationList (page: $page, perPage: $perPage, tag: $tag, name: $name, status: $status) {
      count,
      list { createTime, details, digest, id, img, name, status, tag }
    }
  }
`;

const getFormList = (params) => {
  return SendGQL({
    query: FormList,
    variables: params
  });
};

const Form = `
  query Form ($id: ID) {
    information (id: $id) {
      createTime, details, digest, id, img, name, status, tag 
    }
  }
`;

const getForm = (id) => {
  return SendGQL({
    query: Form,
    variables: {
      id
    }
  });
};

const DeleteForm = `
  mutation DeleteForm ($id: ID) {
    deleteForm (id: $id)
  }
`;

const deleteForm = (id) => {
  return SendGQL({
    query: DeleteForm,
    variables: {
      id
    }
  });
};

const CreateForm = `
  mutation CreateForm ($form: formArgs, $id: ID) {
    createForm(form: $form, id: $id)
  }
`;

const createForm = (data, id) => {
  return SendGQL({
    query: CreateForm,
    variables: {
      id,
      form: data
    }
  });
};

const UpdateForm = `
  mutation UpdateForm ($information: informationArgs, $id: ID) {
    updateForm(id: $id, information: $information)
  }
`;

const updateForm = (data, id) => {
  return SendGQL({
    query: UpdateForm,
    variables: {
      id,
      information: data
    }
  });
};

export {
  getFormList, getForm, deleteForm, createForm, updateForm
};
