<template>
  <div id="app">
    <v-top></v-top>
    <router-view />
    <v-bottom id="bottom"></v-bottom>
    <success-modal></success-modal>
  </div>
</template>

<script>
import VTop from "./components/top";
import VBottom from "./components/bottom";
import SuccessModal from "@/components/successModal";

export default {
  name: "app",
  components: {
    VTop,
    VBottom,
    SuccessModal,
  },
  mounted() {
    function init() {
      let width = document.documentElement.clientWidth;
      width = width > 1920 ? 1920 : width;
      if (width > 750) {
        document.documentElement.style.fontSize = width / 100 + "px";
      } else {
        width = width < 400 ? 400 : width;
        document.documentElement.style.fontSize = width / 72 + "px";
      }
    }
    init();
    window.addEventListener("orientationchange", init);
    window.addEventListener("resize", init);
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  word-spacing: normal;
  color: rgb(141, 144, 149);
  font-family: "SourceHanSansCN-Normal", "SourceHanSansCN-ExtraLight",
    "微软雅黑", sans-serif;
  font-style: normal;
  font-weight: normal;
}
body {
  width: 100%;
  overflow-x: auto;
  background-color: #f3f3f2;
  // min-width: 1280px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #525252;
  display: block;
  overflow: hidden;
  .ant-spin-dot-item {
    background-color: #ffffff;
  }
}
ul li {
  list-style: none;
}
.star-five {
  display: inline-block;
  background: url($QNHost+"/star-five.png") no-repeat;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
}
.blank {
  background-color: #ffffff;
  height: 40px;
}
.background-media {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.mobile-show {
  display: none;
}
.pc-show {
  display: block;
}
@media screen and (max-width: 750px) {
  .background-media {
    background-size: 100%;
    background-attachment: inherit;
  }
  .mobile-show {
    display: block;
  }
  .pc-show {
    display: none !important;
  }
  .margin-bottom-0 {
    margin-bottom: 0;
  }
}
</style>
