<template>
  <div :class="getClass">
    <div class="content">
      <a href="/"
        ><img
          :src="`${QNHost}/logo.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      /></a>
      <span
        :class="'menu-icon' + (mobileNavShow ? ' vertical' : '')"
        @click="handleMenue"
      >
        <i></i>
        <i></i>
        <i></i>
      </span>
      <ul :class="mobileNavShow ? 'mobile-show' : 'mobile'">
        <li v-if="!isCanada"><a href="/">首页</a></li>
        <li>
          <a href="/canada">{{ isCanada ? '首页' : '加拿大' }}</a>
        </li>
        <li v-if="!isCanada"><a href="/america">美国</a></li>
        <li v-if="!isCanada"><a href="/australia">澳洲</a></li>
        <li><a href="/about">关于我们</a></li>
        <li><a href="/link">联系我们</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'top',
  computed: {
    getClass() {
      return { top: true, topBg: this.hasBg };
    }
  },
  data() {
    return {
      hasBg: false,
      mobileNavShow: false,
      isCanada: false
    };
  },
  methods: {
    handleMenue() {
      this.$data.mobileNavShow = !this.$data.mobileNavShow;
    },
    getSecondDomain() {
      const href = window.location.href;
      this.$data.isCanada = href.includes('canada.');
    }
  },
  mounted() {
    this.getSecondDomain();
    document.addEventListener('scroll', () => {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      if (t > 500) {
        !this.hasBg && (this.hasBg = true);
      } else {
        this.hasBg = false;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.top {
  position: fixed;
  top: 2rem;
  left: 0;
  width: 100%;
  min-width: 800px;
  height: 3.5rem;
  padding: 10px;
  z-index: 999;
  font-family: '微软雅黑';
  font-size: 1rem;
  &.topBg {
    background-color: #000000;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 76.6%;
    margin: auto;
    img {
      width: 198px;
      height: 33px;
      cursor: pointer;
    }
  }
  .menu-icon {
    display: none;
    position: fixed;
    right: 0;
    top: 2rem;
    i {
      width: 3rem;
      height: 2px;
      display: block;
      margin: 0 auto 1rem;
      border-radius: 0.2rem;
      background-color: #ffffff;
      box-shadow: 0 0 1px 0 #000000;
    }
  }
  ul {
    display: flex;
    li {
      position: relative;
      padding: 0 3.1rem 0 0;
      cursor: pointer;
      color: #ffffff;
      &:hover {
        text-decoration: underline;
        color: #ffc600;
      }
      a {
        color: #ffffff;
        text-decoration: none;
        word-break: keep-all;
      }

      ol {
        position: absolute;
        top: 1.6rem;
        left: 0;
        width: 100px;
        background-color: #000000;
        display: block;
        padding: 10px 10px 0;
        border-radius: 4px;
        li {
          width: 100%;
          font-size: 12px;
          color: #ffffff;
          text-align: left;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .top,
  .top .menu-icon {
    top: 6.5rem;
  }
  .top {
    padding: 0 10px;
    .content {
      width: 100%;
      img {
        width: 100px;
        height: auto;
      }
    }
    &.topBg {
      background: none;
      .menu-icon {
        top: 1.5rem;
      }
      ul {
        padding-top: 4rem;
      }
    }
    .menu-icon {
      display: block;
      width: 10rem;
      height: 5rem;
      transition: transform 0.5s ease-in-out;
      z-index: 99;
      &.vertical {
        transform: rotate(90deg);
        i:nth-child(2n) {
          display: none;
        }
        i:first-child {
          transform: translateY(4rem) rotate(-45deg);
        }
        i:last-child {
          transform: translateY(2.6rem) rotate(45deg);
        }
      }
    }
    ul {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      width: 14rem;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      padding-top: 12rem;
      &.mobile {
        right: -30rem;
      }
      &.mobile-show {
        right: 0;
        transition: right 0.5s ease-in-out;
      }
      li {
        width: 100%;
        padding: 2rem 0;
        text-align: center;
        font-size: 2.6rem;
      }
    }
  }
}
</style>
