<template>
  <div class="bottom">
    <div class="item">
      <img :src="`${QNHost}/logo.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民" />
      <p class="hongyuan">泓远，全方位海外高端定制服务诚信品牌</p>
      <p class="country">多伦多 / 温哥华 / 芝加哥 / 洛杉矶 <br />香港 / 北京 / 成都</p>
      <span class="phoneNum">咨询热线: +86 28 8337 2981</span>
      <p class="phoneNum">+86 136 9344 9659</p>
    </div>
    <div class="item item1">
      <p class="free">免费测评，定制专属签证方案 >>></p>
      <form class="form">
        <input class="input1" style="margin-right: 20px;" type="text" v-model="formData.name" placeholder="请输入您的姓名：" />
        <input class="input2" type="text" v-model="formData.phone" placeholder="请输入您的电话：" /><br />
        <div class="now" @click="createForm"><a-spin v-if="loading"></a-spin>立即咨询</div>
      </form>
      <div class="qrcode-text">
        <div class="qrcode">
          <div>
            <img :src="`${QNHost}/Link/04.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民" />
            <p>扫码关注<br />微信公众号</p>
          </div>
          <div>
            <img :src="`${QNHost}/Link/03.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民" />
            <p>扫码咨询<br />资深签证专家</p>
          </div>
        </div>
        <p class="bottomText"><a href="https://beian.miit.gov.cn/">蜀ICP备2022000746号-1 </a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { createForm } from "../../assets/api/form";

export default {
  name: "bottom",
  data() {
    return {
      formData: {},
      loading: false,
    };
  },
  methods: {
    async createForm() {
      if (this.loading) return;
      if (this.formData.name && this.formData.phone) {
        this.loading = true;
        const resp = await createForm(this.formData);
        this.loading = false;
        if (resp && resp.createForm) {
          this.formData = {};
          this.$notification.success({
            message: "提交成功！",
          });
          this.$store.dispatch("setVisible", true);
        }
      } else if (this.formData.name) {
        alert("请输入您的电话！");
      } else if (this.formData.phone) {
        alert("请输入您的姓名！");
      } else {
        alert("请输入您的姓名和电话！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  text-align: left;
  background-color: #1e1e1e;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 80px 0 40px;
  color: #ffffff;
  .item {
    font-size: 1.3rem;
    input {
      height: 40px;
      width: 280px;
      border-radius: 10px;
      font-size: 15px;
      color: #3a3a3a;
      text-indent: 1.2em;
    }
    .now {
      height: 40px;
      width: 280px;
      border-radius: 10px;
      margin-right: 20px;
      font-size: 20px;
      margin-top: 15px;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      background-color: #996c33;
    }
    .country {
      margin-top: 20px;
      letter-spacing: 5px;
    }

    .hongyuan {
      color: #ab7800;
      display: block;
      line-height: 35px;
      border-top: 2px #ab7800 solid;
      border-bottom: 2px #ab7800 solid;
      margin-top: 2.3rem;
    }
    .qrcode {
      display: flex;
      img {
        height: 8rem;
        width: auto;
        margin-top: 40px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        text-align: center;
      }
    }
    .free {
      font-size: 20px;
    }
  }
  .bottomText {
    display: block;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .bottom {
    display: block;
    padding: 8rem 0;
    > .item {
      width: 40rem;
      margin: auto;
      .hongyuan {
        font-size: 2.1rem;
        line-height: 2rem;
        padding: 1.3rem 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      input {
        margin-bottom: 1rem;
      }
      input,
      .now {
        width: 100%;
        margin-right: 0;
        height: 4.5rem;
        font-size: 2.2rem;
        line-height: 4.5rem;
      }
      .country {
        font-size: 2rem;
        line-height: 3rem;
        letter-spacing: 0.4rem;
      }
      .phoneNum {
        font-size: 2.1rem;
      }
      .free {
        color: #d4ae7e;
        font-size: 2.1rem;
      }
      .qrcode-text {
        display: block;
        .bottomText {
          margin-top: 7.3rem;
          text-align: left;
          font-size: 1.8rem;
        }
      }
      .qrcode {
        justify-content: space-between;
        img {
          width: 16rem;
          height: auto;
          margin-right: 0;
        }
        p {
          font-size: 2.4rem;
        }
      }
    }
  }
}
</style>
