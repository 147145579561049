import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import { notification, Result, Modal, Button, Spin } from "ant-design-vue";

Vue.prototype.$notification = notification;

Vue.config.productionTip = false;
Vue.prototype.QNHost = "https://image.hopewinner.com";

Vue.use(Result);
Vue.use(Modal);
Vue.use(Button);
Vue.use(Spin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
