<template>
  <div class="home" id="Home" style="background-color:#f3f3f2;">
    <div class="swiper">
      <div class="item">
        <div class="imgs">
          <img
            class="swiperFirst"
            :src="`${QNHost}/Home/banner1.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="swiperSecond"
            :src="`${QNHost}/Home/banner2.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="imgs mobile">
          <img
            class="swiperFirst"
            :src="`${QNHost}/Home/mobile/1.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="swiperSecond"
            :src="`${QNHost}/Home/mobile/2.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="txt">
          <p class="big">专注大国签证服务</p>
          <p class="middle">安心签证，就找泓远</p>
          <p class="english first">IMMIGRATION IS FOR A BETTER LIFE</p>
          <p class="english second">IN THE FUTURE</p>
        </div>
      </div>
    </div>
    <div class="slogan">
      <p>泓远，全方位大国签证服务诚信品牌</p>
      <p class="small">身份规划<i></i><span>∙</span><i></i>乐居海外</p>
    </div>

    <div class="slogan-img">
      <img
        :src="`${QNHost}/Home/home2.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="slogan-txt">
        <p class="big">泓远 ，</p>
        <p class="xiao">专注大国签证，坚持塑造全业务链品牌</p>
        <p class="other">
          泓远专注办理美、加、澳永居签证，提供全方位专业、细致的签证申请服务，同时提供后续安家服务，
          协助申请人乐居海外。
        </p>
<!--         <p class="other gone">
          目前，泓远已经成为了一家全业务链公司，自主研发、全球移民、财富管理、海外生活，环环相扣，为客户争取更高的利益，并保障客户快乐移民。
        </p> -->
      </div>
      <div class="moblie">
        <img
          class="one"
          :src="`${QNHost}/Home/classify.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>
    <section class="home-form">
      <img
        :src="`${QNHost}/Home/indexBg.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <form>
        <input type="text" placeholder="您的姓名：" v-model="indexData.name" />
        <input
          class="phone"
          type="text"
          placeholder="您的电话："
          v-model="indexData.phone"
        />
        <div class="now" @click="createForm">
          <a-spin v-if="loading"></a-spin>立即咨询
        </div>
      </form>
    </section>
    <div class="project-title">
      <h5>
        <span class="star"></span><span class="tuijian">推荐移民项目</span
        ><span class="star"></span>
      </h5>
      <p>总有一款适合您</p>
    </div>

    <div class="project-list">
      <div class="img">
        <img
          :src="`${QNHost}/Home/canada.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile"
          :src="`${QNHost}/Home/canada.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
      <div class="content item-1">
        <img
          style="height：100%; width:100%"
          :src="`${QNHost}/Home/fengye.jpg`"
          alt=""
        />
        <div class="info">
          <h5 class="recommend">
            <span class="star"></span><span>特别推荐</span
            ><span class="star"></span>
          </h5>
          <h2 class="title">
            加拿大联邦创业投资移民<span>START-UP VISA PROGRAM</span>
          </h2>
          <h3 class="sub-title">创新型商业项目开发者和投资者的理想选择</h3>
          <p class="animate">
            加拿大联邦创业投资移民的宗旨在于吸引有技术专长和创业潜能的国际人才、企业家，来加创办创新型企业和定居，帮助加拿大引进新的技术知识，为当地居民创造更多就业机会，并为经济增长和提高全球竞争力做出贡献。
          </p>
          <p class="animate">
            2017年7月，加拿大移民部公布将加拿大联邦创业投资移民转为正式移民政策，该项目成为未来10年的主流移民途径是大势所趋！
          </p>
          <div class="more-btn CanadaBtn">
            <p><a href="/canadaVisa">了解详情</a></p>
            <p><a href="/canada">更多加拿大移民项目</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="project-list reverse">
      <div class="img flex-start">
        <img
          :src="`${QNHost}/Home/unitedStates.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile"
          :src="`${QNHost}/Home/mobile/unitedStates.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
      <div class="content none item-2">
        <div class="info1">
          <h2 class="title">
            美国EB-1A杰出人才移民<span>EXTRAORDINARY ABILITY ALIENS</span>
          </h2>
          <h3 class="sub-title">高端人才的极速美国移民途径</h3>
          <p class="animate">
            美国EB-1A杰出人才移民属于美国职业移民第一类优先类别。美国政府通过EB-1A杰出人才移民，积极吸引招纳世界各国优秀人才。
          </p>
          <p class="animate">
            美国移民法规定，满足“杰出人才”标准的外籍人士，无需申请劳工证，不受语言、学历和年龄限制，可以直接申请移民并获得绿卡。
          </p>
          <div class="more-btn">
            <p><a href="/americaExtraordinary">了解详情</a></p>
            <p><a href="/america">更多美国移民项目</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="project-list">
      <div class="img">
        <img
          :src="`${QNHost}/Home/australia.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile"
          :src="`${QNHost}/Home/mobile/australia.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
      <div class="content none item-3">
        <div class="info1">
          <h2 class="title">
            188C澳洲显赫投资者签证<span>SIGNIFICANT INVESTOR VISA</span>
          </h2>
          <h3 class="sub-title">高净值人群的澳洲移民快捷通道</h3>
          <p class="animate">
            澳洲188C显赫投资者签证是澳洲政府为了吸引能为澳洲经济做出巨大投资贡献的申请人特别设立的临居类签证。
          </p>
          <p class="animate">
            相对于其他商业移民签证而言，该签证对申请人没有年龄上限的要求，不需要进行评分，居住要求非常宽松，只需在澳洲投资500万澳币即可。
          </p>
          <div class="more-btn">
            <p><a href="/australia_2">了解详情</a></p>
            <p><a href="/australia">更多澳洲移民项目</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="blank"></div>

    <div class="advertise">
      <div class="background-media"></div>
      <div class="ctx">
        <div>
          <h5>快乐移民，就找<span>泓远</span></h5>
          <p class="line"><span></span></p>
          <p class="txt">
            <span>安心</span><i>∙</i><span>省心</span><i>∙</i><span>舒心</span
            ><i>∙</i><span>放心</span>
          </p>
        </div>
      </div>
    </div>

    <div class="service">
      <div class="con">
        <h5>泓远360°延伸服务</h5>
        <p>爱如家人•责任如山</p>
        <div class="icons">
          <div>
            <span class="icon icon-1"></span>
            <span class="icon icon-2"></span>
            <span class="icon icon-3"></span>
          </div>
          <div>
            <span class="icon icon-4"></span>
            <span class="icon icon-5"></span>
            <span class="icon icon-6"></span>
          </div>
        </div>
      </div>
    </div>

<!--     <div class="advertise more-info">
      <img
        :src="`${QNHost}/Home/more.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile"
        :src="`${QNHost}/Home/mobile/3.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="ctx">
        <div class="info">
          <h5>快乐移民，就找<span>泓远</span></h5>
          <p class="line"><span></span></p>
          <p class="txt">
            <span>安心</span><i>∙</i><span>省心</span><i>∙</i><span>舒心</span
            ><i>∙</i><span>放心</span>
          </p>
          <div class="tip">
            <p>泓远拥有国际化资深团队</p>
            <p>坚持带领客户进行项目实地考察</p>
            <p>并为客户提供丰富多彩的拓展活动</p>
            <p>在保障客户资金、绿卡安全的前提下</p>
            <p>始终拥有快乐移民感受... ...</p>
          </div>
          <a href="/about" class="btn">查看更多</a>
        </div>
      </div>
    </div> -->

    <!-- <div class="consult">
      <h5>一分钟了解最IN资讯</h5>
      <p class="brief">更全面、更及时地了解全球移民政策变动及泓远动态。</p>
      <div class="list">
        <div class="item item-1">
          <div class="tag">
            <img
              :src="domainName + news.flash.list[0].img"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
            <p>移民快讯</p>
          </div>
          <div
            class="con"
            v-if="news.flash && news.flash.list"
            @click="
              toDetail(news.flash.list[0].id, news.flash.list[0].createTime)
            "
          >
            <h6>{{ news.flash.list[0].name }}</h6>
            <p style="line-height:1.4rem;">{{ news.flash.list[0].digest }}</p>
          </div>
        </div>
        <div class="item item-2">
          <div class="tag">
            <img
              :src="domainName + news.dynamic.list[0].img"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
            <p>泓远动态</p>
          </div>
          <div
            class="con"
            v-if="news.dynamic && news.dynamic.list"
            @click="
              toDetail(news.dynamic.list[0].id, news.dynamic.list[0].createTime)
            "
          >
            <h6>{{ news.dynamic.list[0].name }}</h6>
            <p style="line-height:1.4rem;">{{ news.dynamic.list[0].digest }}</p>
          </div>
        </div>
        <div class="item item-3">
          <div class="tag">
            <img
              :src="domainName + news.life.list[0].img"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
            <p>海外生活</p>
          </div>
          <div
            class="con"
            v-if="news.life && news.life.list"
            @click="
              toDetail(news.life.list[0].id, news.life.list[0].createTime)
            "
          >
            <h6>{{ news.life.list[0].name }}</h6>
            <p style="line-height:1.4rem;">{{ news.life.list[0].digest }}</p>
          </div>
        </div>
      </div>
      <div class="btn"><a href="/new">点击查看更多></a></div>
    </div> -->

    <div class="about">
      <img
        :src="`${QNHost}/Home/about.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <div class="item item-1">
          <h5>关于我们</h5>
          <p class="en">About Us</p>
          <p class="brief">
            泓远，专注高性价比【大国移民】，助您快乐移民、轻松实现海外梦。
          </p>
          <div class="btn"><a href="/about">点击查看更多></a></div>
        </div>
        <div class="item item-2">
          <h5>联系我们</h5>
          <p class="en">Contract Us</p>
          <p class="brief">
            助您快速定位适合移民国家，为您精心定制专属移民方案。
          </p>
          <div class="btn"><a href="/link">点击查看更多></a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";
import { getInformationList } from "@/assets/api";
import { createForm } from "../../assets/api/form";

export default {
  name: "home",
  data() {
    return {
      domainName: "https://image.hopewinner.com/",
      scrollReveal: null,
      news: {
        flash: {
          list: [{}],
        },
        dynamic: {
          list: [{}],
        },
        life: {
          list: [{}],
        },
      },
      indexData: {},
      loading: false,
    };
  },
  methods: {
    async createForm() {
      if (this.loading) return;
      if (this.indexData.name && this.indexData.phone) {
        this.loading = true;
        const resp = await createForm(this.indexData);
        this.loading = false;
        if (resp && resp.createForm) {
          this.indexData = {};
          this.$notification.success({
            message: "提交成功！",
          });
          this.$store.dispatch("setVisible", true);
        }
      } else if (this.indexData.name) {
        alert("请输入您的电话！");
      } else if (this.indexData.phone) {
        alert("请输入您的姓名！");
      } else {
        alert("请输入您的完整信息！");
      }
    },
    animateCSS(element, animationName) {
      const node = document.querySelector(element);
      node.classList.add("animated", animationName);
      function handleAnimationEnd() {
        node.classList.remove("animated", animationName);
        node.removeEventListener("animationend", handleAnimationEnd);
      }
      node.addEventListener("animationend", handleAnimationEnd);
    },
    async getInformationList() {
      const data = await getInformationList({ perPage: 1 });
      this.news = data;
    },
    toDetail(id, createTime) {
      this.$router.push({
        path: "/newDetail",
        query: { id, createTime },
      });
    },
  },
  mounted() {
    this.getInformationList();
    const scrollReveal = ScrollReveal();
    this.scrollReveal = scrollReveal;

    let num = 0;
    setInterval(() => {
      if (num === 0) {
        this.animateCSS(".swiperFirst", "fadeOut");
        this.animateCSS(".swiperSecond", "fadeIn");
        num = 1;
      } else {
        this.animateCSS(".swiperFirst", "fadeIn");
        this.animateCSS(".swiperSecond", "fadeOut");
        num = 0;
      }
    }, 3000);

    scrollReveal.reveal(".swiper .txt", {
      distance: "400px",
      duration: 1200,
      easing: "ease-in-out",
      origin: "left",
    });
    scrollReveal.reveal(".slogan", {
      distance: "400px",
      duration: 1600,
      easing: "ease-in-out",
      origin: "bottom",
    });
    scrollReveal.reveal(".slogan-txt .big", {
      distance: "400px",
      duration: 1000,
      easing: "ease-in-out",
      origin: "right",
    });
    scrollReveal.reveal(".slogan-txt .other", {
      distance: "400px",
      duration: 1400,
      easing: "ease-in-out",
      origin: "left",
    });
    scrollReveal.reveal(".slogan-img .one", {
      duration: 1000,
      easing: "ease-in-out",
      rotate: {
        z: 160,
      },
    });
    scrollReveal.reveal(".project-title h5, .project-title p", {
      distance: "400px",
      duration: 1100,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".project-list .item-1 .info", {
      distance: "800px",
      duration: 1000,
      easing: "ease-in-out",
      origin: "right",
    });
    scrollReveal.reveal(".project-list .item-2 .info", {
      distance: "800px",
      duration: 1200,
      easing: "ease-in-out",
      origin: "left",
    });
    scrollReveal.reveal(".project-list .item-3 .info", {
      distance: "800px",
      duration: 1400,
      easing: "ease-in-out",
      origin: "right",
    });
    scrollReveal.reveal(".advertise h5, .advertise p", {
      distance: "400px",
      duration: 1000,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".service .icon", {
      duration: 1000,
      easing: "ease-in-out",
      rotate: { z: 160 },
    });
    scrollReveal.reveal(".advertise .btn", {
      distance: "400px",
      duration: 1400,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".consult .item-1", {
      distance: "400px",
      duration: 1000,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".consult .item-2", {
      distance: "400px",
      duration: 1200,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".consult .item-3", {
      distance: "400px",
      duration: 1400,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".about .item-1", {
      distance: "400px",
      duration: 1000,
      easing: "ease-in-out",
    });
    scrollReveal.reveal(".about .item-2", {
      distance: "400px",
      duration: 1200,
      easing: "ease-in-out",
    });
  },
  beforeDestroy() {
    ScrollReveal().destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
