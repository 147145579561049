const remoteAddress = '';

export default function(queryPayloadObject) {
  return fetch(remoteAddress + '/api/graphql', {
    method: 'POST',
    body: JSON.stringify({
      variables: queryPayloadObject.variables,
      query: queryPayloadObject.query
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('--Network Error--');
    }
  }).then(json => {
    // dig into two layer
    // tslint:disable-next-line:forin
    if (json.errors) {
      alert(json.errors[0].message);
      return false;
    }
    return json.data;
  });
}
