import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    visible: false,
  },
  mutations: {
    setVisible(state, visible) {
      state.visible = visible;
    },
  },
  actions: {
    setVisible({ commit }, visible) {
      commit("setVisible", visible);
    },
  },
  modules: {},
});
