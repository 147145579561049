import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About')
  },
  {
    path: '/link',
    name: 'link',
    component: () => import(/* webpackChunkName: "about" */ '../views/Link')
  },
  {
    path: '/new',
    name: 'new',
    component: () => import(/* webpackChunkName: "about" */ '../views/New')
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewDetail')
  },
  {
    path: '/canada',
    name: 'canada',
    component: () => import(/* webpackChunkName: "about" */ '../views/Canada')
  },
  {
    path: '/america',
    name: 'america',
    component: () => import(/* webpackChunkName: "about" */ '../views/America')
  },
  {
    path: '/australia',
    name: 'australia',
    component: () => import(/* webpackChunkName: "about" */ '../views/Australia')
  },
  {
    path: '/canadaVisa',
    name: 'canadaVisa',
    component: () => import(/* webpackChunkName: "about" */ '../views/CanadaVisa')
  },
  {
    path: '/canadaSponsored',
    name: 'canadaSponsored',
    component: () => import(/* webpackChunkName: "about" */ '../views/CanadaSponsored')
  },
  {
    path: '/quebec',
    name: 'quebec',
    component: () => import(/* webpackChunkName: "about" */ '../views/Quebec')
  },
  {
    path: '/canadaEmployed',
    name: 'canadaEmployed',
    component: () => import(/* webpackChunkName: "about" */ '../views/CanadaEmployed')
  },
  {
    path: '/americaManager',
    name: 'americaManager',
    component: () => import(/* webpackChunkName: "about" */ '../views/AmericaManager')
  },
  {
    path: '/americaInvestor',
    name: 'americaInvestor',
    component: () => import(/* webpackChunkName: "about" */ '../views/AmericaInvestor')
  },
  {
    path: '/americaEmploy',
    name: 'americaEmploy',
    component: () => import(/* webpackChunkName: "about" */ '../views/AmericaEmploy')
  },
  {
    path: '/americaExtraordinary',
    name: 'americaExtraordinary',
    component: () => import('../views/AmericaExtraordinary')
  },
  {
    path: '/australia_1',
    name: 'australia_1',
    component: () => import('../views/Australia_1')
  },
  {
    path: '/australia_2',
    name: 'australia_2',
    component: () => import('../views/Australia_2')
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('../views/Customer')
  },
  {
    path: '/customer_1',
    name: 'customer_1',
    component: () => import('../views/Programmer')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to,) => {
  document.documentElement.scrollTop && (document.documentElement.scrollTop = 0);
  document.body.scrollTop && (document.body.scrollTop = 0);
  if (!to.name) {
    router.push('/');
  }
});

export default router;
