import SendGQL from './SendGQL';

const InformationList = `
  query InformationList ($perPage: Int!) {
    flash: informationList (page: 1, perPage: $perPage, tag: "移民快讯", status: "展示") {
      list { createTime, digest, id, img, name, status, tag }
    },
    dynamic: informationList (page: 1, perPage: $perPage, tag: "泓远动态", status: "展示") {
      list { createTime, digest, id, img, name, status, tag }
    },
    life: informationList (page: 1, perPage: $perPage, tag: "海外生活", status: "展示") {
      list { createTime, digest, id, img, name, status, tag }
    }
  }
`;

const getInformationList = (params) => {
  return SendGQL({
    query: InformationList,
    variables: params
  });
};

const Information = `
  query Information ($id: ID) {
    information (id: $id) {
      createTime, details, digest, id, img, name, status, tag 
    }
  }
`;

const getInformation = (id) => {
  return SendGQL({
    query: Information,
    variables: {
      id
    }
  });
};

const InformationNext = `
  query InformationNext ($createTime: Int, $sort: Int) {
    informationNext (createTime: $createTime, sort: $sort) {
      createTime, details, digest, id, img, name, status, tag 
    }
  }
`;
const informationNext = (createTime, sort) => {
  return SendGQL({
    query: InformationNext,
    variables: {
      createTime, sort
    }
  });
};

export {
  getInformationList, getInformation, informationNext
};
