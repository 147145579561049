<template>
  <a-modal
    v-model="visible"
    centered
    title=""
    @cancel="handleClose"
    :footer="null"
  >
    <a-result
      status="success"
      title="恭喜您"
      sub-title="您已经成功提交您的电话"
    >
      <template #extra>
        <img
          :src="`${QNHost}/Link/03.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />

        <div>
          <br />
          <a-button key="console" type="primary" @click="handleClose">
            关闭
          </a-button>
        </div>
      </template>
    </a-result>
  </a-modal>
</template>

<script>
export default {
  name: "SuccessModal",
  computed: {
    visible: {
      get: function() {
        return this.$store.state.visible;
      },
      set: function(v) {
        return v;
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$store.dispatch("setVisible", false);
    },
  },
};
</script>
